import React from 'react';
import { NavLink } from 'react-router-dom';

class Navigation extends React.Component {

    componentDidMount() {
        function loadScript(src) {

            return new Promise(function (resolve, reject) {
                var script = document.createElement('script');
                script.src = src;
                script.addEventListener('load', function () {
                    resolve();
                });
                script.addEventListener('error', function (e) {
                    reject(e);
                });
                document.body.appendChild(script);
                document.body.removeChild(script);
            })
        };

        loadScript('./assets/js/masonary.js');

    };

   scrollInto(id) {
        const element = document.getElementById(id);
       setTimeout(function () {
           element.scrollIntoView({
               block: "center",
               behavior: "smooth",
           });
       }, 100);
    }



    render() {

        return (
            <>
                <div className={(this.props.bgcolor !== '') ? `header-nav navbar-collapse collapse ${this.props.bgcolor}` : 'header-nav navbar-collapse collapse'}>
                    <ul className="nav navbar-nav">
                        <li className="active" onClick={() => this.scrollInto('ankerHome')}>
                            <a>Home</a>
                        </li>
                        <li onClick={() => this.scrollInto('ankerUeberUns')}>
                        <a>Über uns</a>
                        </li>
                        <li onClick={() => this.scrollInto('ankerDienstleistungen')}>
                        <a>Dienstleistungen</a>
                        </li>
                        <li onClick={() => this.scrollInto('ankerProjekte')}>
                            <a>Projekte</a>
                        </li>
                        <li onClick={() => window.open("/impressum", '_blank')?.focus()}>
                            <a>Impressum</a>
                        </li>
                    </ul>
                </div>
            </>
        );
    };
};

export default Navigation;
