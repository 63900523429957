import React from 'react';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const testimonials = [
    {
        image: require('./../../images/testimonials/pic1.jpg'),
        reviewername: 'Lutz Liebig',
        position: 'Privat Person',
        review: 'Mir wurde Herr Senkler von einem Kollegen empfohlen, der ihn besonders für die Vielseitigkeit seiner Arbeit schätzte. Er hat uns wunderschöne und moderne Spachteltechnick im Wohnzimmer und in der Küche angebracht. Alles zu einem fairen Preis! 10/10'
    },
    {
        image: require('./../../images/testimonials/pic2.jpg'),
        reviewername: 'Maximilian Bauer',
        position: 'Privat Person',
        review: 'Senkler Stuckateur hat unser Haus in ein Kunstwerk verwandelt! Mit unglaublichem Geschick und Sinn für Detail hat das Team nicht nur unsere Fassade neu verputzt, sondern auch im Innenbereich mit Stuckarbeiten für das gewisse Extra gesorgt.'
    },
    {
        image: require('./../../images/testimonials/pic3.jpg'),
        reviewername: 'Johanna Schmidt',
        position: 'Ladenbesitzer',
        review: 'Die Zusammenarbeit mit Senkler Stuckateur war von Anfang bis Ende eine Freude. Herr Senkler und sein Team haben unsere Erwartungen übertroffen, indem sie unsere Räume mit feinsten Verputzarbeiten und Stuckdetails verschönert haben, die perfekt zu unserem Stil passen.'
    },
    {
        image: require('./../../images/testimonials/pic4.jpg'),
        reviewername: 'Deniz Yilmaz',
        position: 'Privatperson',
        review: 'Ich wollte schon immer einen mediterranen Flair in meinem Zuhause haben, und Senkler Stuckateur hat diesen Traum wahr gemacht! Mit einer beeindruckenden Auswahl an Verputztechniken haben sie mein Wohnzimmer und Badezimmer in eine südländische Oase verwandelt.'
    },
]

var bnr1 = require('./../../images/background/bg6.jpg');

class Testimonials extends React.Component {
    render() {

        const options = {
            loop:true,
            autoplay:true,
            margin:30,
            nav:false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive:{
                0:{
                    items:1
                },
                991:{
                    items:2
                }
            }
        };

        return (
            <>
                <div className="section-full mobile-page-padding p-t80 p-b50 square_shape2 bg-cover" style={{backgroundImage:"url(" + bnr1 + ")"}}>
                    <div className="container">
                        <div className="section-content">
                            {/* TITLE START */}
                            <div className="section-head">
                                <div className="mt-separator-outer separator-center">
                                    <div className="mt-separator">
                                        <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">Kunden</span> Erfahrungen</h2>
                                    </div>
                                </div>
                            </div>
                            {/* TITLE END */}
                            {/* TESTIMONIAL START */}
                            <OwlCarousel className="owl-carousel testimonial-home" {...options}>
                            
                                {testimonials.map((item, index) => (
                                    <div key={index} className="item">
                                        <div className="testimonial-2 m-a30 hover-animation-1">
                                            <div className=" block-shadow bg-white p-a30">
                                                <div className="testimonial-detail clearfix">
                                                    <h4 className="testimonial-name m-b5">{item.reviewername}</h4>
                                                    <span className="testimonial-position">{item.position}</span>
                                                </div>
                                                <div className="testimonial-text">
                                                    <span className="fa fa-quote-right" />
                                                    <p> {item.review}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </OwlCarousel>    
                               
                            
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default Testimonials;
