import React from 'react';

class Impressum extends React.Component {
    render() {
        return (
            <div style={{
                marginLeft: "2rem"
            }}>
                <h1>Impressum</h1>

                <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
                <p>Ricardo Senkler<br/>
                    Stuckateur<br/>
                    Gutenberg Str. 7<br/>
                    52249 Eschweiler</p>

                <h2>Kontakt</h2>
                <p>Telefon: 01776838111<br/>
                    E-Mail: r.senklerstuckateur@gmail.com</p>

                <h2>Umsatzsteuer-ID</h2>
                <p>Umsatzsteuer-Identifikationsnummer gem&auml;&szlig; &sect; 27 a Umsatzsteuergesetz:<br/>
                    56 784 396 014</p>

                <h2>Berufsbezeichnung und berufsrechtliche Regelungen</h2>
                <p>Berufsbezeichnung:<br/>
                    Stuckateur</p>
                <p>Zust&auml;ndige Kammer:<br/>
                    Handwerkskammer Aachen<br/>
                    52062 Aachen, Sandkaulbach 17-21<br/>
                    52086 Aachen, Postfach 500234<br/>
                    www.hwk-aachen.de</p>
                <p>Verliehen in:<br/>
                    Deutschland</p>
                <h2>Redaktionell verantwortlich</h2>
                <p>Ricardo Senkler<br/>
                    Stuckateur<br/>
                    Gutenberg Str. 7<br/>
                    52249 Eschweiler</p>

                <h2>EU-Streitschlichtung</h2>
                <p>Die Europ&auml;ische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit: <a
                    href="https://ec.europa.eu/consumers/odr/" target="_blank"
                    rel="noopener noreferrer">https://ec.europa.eu/consumers/odr/</a>.<br/> Unsere E-Mail-Adresse finden
                    Sie oben im Impressum.</p>

                <h2>Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle</h2>
                <p>Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer
                    Verbraucherschlichtungsstelle teilzunehmen.</p>

                <p>Quelle: <a
                    href="https://www.e-recht24.de/impressum-generator.html">https://www.e-recht24.de/impressum-generator.html</a>
                </p>
            </div>
        );
    };
};

export default Impressum;
